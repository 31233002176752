import {Get, Post} from '../hooks/remoteCall';
import {requestFundsPath, resetTimerPath} from './apiEndpoints';


export interface FundsRequest {
    walletAddress: string;
    captchaResponse: string;
}

export interface FundsResponse {
   amount: number;
   transactionHash: string;
}

export function requestFunds(req: FundsRequest): Promise<FundsResponse> {
    return Post<FundsResponse>(requestFundsPath, req);
}

export function requestTimer(): Promise<number> {
    return Get<number>(resetTimerPath);
}
