import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        flexGrow: 0.01
    },
    title: {
        flexGrow: 1,
    },
    icon: {
        marginLeft: theme.spacing(1),
    },
    toolbar: {
        justifyContent: "space-around"
    }
}));

const Header = () => {
    const classes = useStyles();
    return (
        <div className={'header'}>
            <img
                src="https://uploads-ssl.webflow.com/5c079ccf77adc592e5737e16/5c0906f1a482fe3bb6b101ae_logo.svg"
                loading="lazy" width="150" alt=""/>

            <div className={'header-right'}>
                <a href="https://twitter.com/setherplatform" className="followus w-inline-block">
                    <div className="text-block">Follow us</div>
                    <img
                        src="https://uploads-ssl.webflow.com/5c079ccf77adc592e5737e16/5c079cd077adc55b98737e98_social-18.svg"
                        loading="lazy" alt="" className="image-4"/>
                </a>
            </div>
        </div>
    );
}

export default Header;
