import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';

import './index.css';
import App from './components/App';

const theme = createTheme({
    palette: {
        type: 'light',
        background: {
            default: "#ffffff"
        },
        primary: {
            main: "#000000"
        },
        secondary: {
            main: "#21ca36"
        }
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
