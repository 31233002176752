import React, {FormEvent, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LinearProgress, Link, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {FundsRequest, FundsResponse, requestFunds, requestTimer} from '../services/requestFunds';
import {useRecaptcha} from '../hooks/recaptcha';
import {environment} from '../environment';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // IE 11.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        fontFamily: 'Inter, sans-serif'
    },
    snackbar: {
        marginBottom: '2rem'
    }
}));

const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FaucetForm = () => {
    const classes = useStyles();
    const [address, setAddress] = useState("");
    const [fundsResponse, setFundsResponse] = useState({} as FundsResponse);
    const [inProgress, setInProgress] = useState(false);
    const [count, setCount] = useState(0);
    const [openErr, setOpenErr] = React.useState(false);
    const [openFunded, setOpenFunded] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const executeCaptcha = useRecaptcha({
        sitekey: environment.captchaSiteKey,
    });

    let timerId: any
    const updateCount = () => {
        timerId = !timerId && setInterval(() => {
            setCount(prevCount => prevCount - 1)
        }, 1000)

        if (count <= 0) clearInterval(timerId)
    }

    useEffect(() => {
        updateCount()
        return () => clearInterval(timerId)
    }, [count])


    useEffect(() => {
        const _requestTimer = async () => {
            const resetTimeResult = await requestTimer();
            setCount((resetTimeResult as any).seconds)
        }
        _requestTimer()
            .catch(console.error);
    }, [fundsResponse, error]);

    const handleSubmit = (e: FormEvent) => doRequestFunds(e);
    const doRequestFunds = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setInProgress(true);
            const token = await executeCaptcha(address);
            const res = await requestFunds({
                walletAddress: address,
                captchaResponse: token,
            } as FundsRequest)
            setFundsResponse(res);
            setOpenFunded(true);
            setInProgress(false);
        } catch (err: any) {
            if (err.response && err.response.data) {
                setOpenErr(true);
                setError(err.response.data);
            } else {
                setError(err);
            }
            setInProgress(false);
        }
    };

    const handleCloseFunded = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFunded(false);
    };
    const handleCloseErr = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErr(false);
    };

    return (
        <>
            { inProgress && <LinearProgress color="secondary" /> }
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5" className={classes.title}>
                        {
                            inProgress ? 'Requesting...' : 'Request Testnet Funds'
                        }
                    </Typography>
                    <div style={{marginTop: '2rem', textAlign: 'center'}} className={classes.title}>
                        <p>For fair usage, we allow a single request for an address and a max of 5 requests from the same IP over a 5 minute time window</p>
                    </div>

                    <div style={{marginTop: '2rem', textAlign: 'center'}} className={classes.title}>
                        Time until next reset: {count} sec.
                    </div>

                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="address"
                            label="Sether Testnet Address"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={inProgress}
                            className={classes.submit}
                        >
                            Request 50 SETHN
                        </Button>
                    </form>

                    {
                        fundsResponse.transactionHash &&
                        <div style={{marginTop: '2rem', textAlign: 'center'}} className={classes.title}>
                            <Link style={{fontWeight: 'bold', fontSize: '16px', color: '#e6923b'}} href={`https://explorer-test.sether.com/tx/${fundsResponse.transactionHash}`}>
                                View Transaction on Sether Explorer
                            </Link>
                        </div>
                    }
                </div>
            </Container>

            <Snackbar open={openFunded} autoHideDuration={3000} onClose={handleCloseFunded}>
                <Alert severity="success" onClose={handleCloseFunded}>
                    You received    {fundsResponse.amount} SETHN to {address}
                </Alert>
            </Snackbar>
            <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseErr} className={classes.snackbar}>
                <Alert severity="error" onClose={handleCloseErr}>
                    ERROR: {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default FaucetForm;
